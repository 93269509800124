import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import ListingExtraction from "./components/tools/ListingExtraction";
import UploadComplete from "./components/tools/UploadComplete";

import { auth } from "./services/firebase";
class App extends Component {
  constructor() {
    super();
    this.state = {
      authenticated: false,
      loading: true,
    };
  }

  componentDidMount() {
    if (!auth().currentUser) {
      auth()
        .signInAnonymously()
        .then(() => {
          // Signed in..
          // console.log(auth().currentUser.uid);
        });
    }
    this.setState({
      authenticated: false,
      loading: false,
    });
  }

  render() {
    return this.state.loading === true ? (
      <div className="text-center align-middle">
        <div className="spinner-grow text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <Router>
        <Switch>
          {/* <Route exact path="/" render={props => <Index {...props} />} /> */}
          <Route path="/uploadcomplete/:catId" component={UploadComplete} />

          <Route path="/uploadcomplete" component={UploadComplete} />
          <Route path="/listingextraction" component={ListingExtraction} />
          <Route exact path="/" component={ListingExtraction} />
        </Switch>
      </Router>
    );
  }
}

export default App;
