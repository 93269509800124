import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import 'firebase/firestore';
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBePPxaEBZY5pxmQixRNhSW0pwolPJ7QtQ",
  authDomain: "inventory-demo-prod.firebaseapp.com",
  databaseURL: "https://inventory-demo-prod-default-rtdb.firebaseio.com",
  projectId: "inventory-demo-prod",
  storageBucket: "inventory-demo-prod.appspot.com",
  messagingSenderId: "469600310644",
  appId: "1:469600310644:web:b1f8405535de07b1084821",
  measurementId: "G-D7VXXT4WKL",
};

const app = firebase.initializeApp(firebaseConfig);
firebase.analytics(app);

export const auth = firebase.auth;
export const db = firebase.database();
export const firestore = firebase.firestore();
