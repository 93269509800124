import React, { Component } from "react";
import { auth } from "../../services/firebase";
import { db } from "../../services/firebase";
import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";
import classnames from "classnames";
import validator from "validator";
import "../tools/Dashboard.css";

import {
  Button,
  Card,
  CardHeader,
  // CardTitle,
  CardBody,
  // CardFooter,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

export default class UploadComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: auth().user,
      files: { status: "Starting", product_value: 0 },
      content: "",
      readError: null,
      writeError: null,
      loadingFiles: true,
    };
    this.validateEmail = this.validateEmail.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.myRef = React.createRef();
  }

  async componentDidMount() {
    this.setState({ readError: null, loadingFiles: true });
    // console.log(this.props.match.params.catId);

    try {
      db.ref("files")
        .child(this.props.match.params.catId)
        // .child("broken")
        .on("value", (snapshot) => {
          if (snapshot.val()) {
            let files = snapshot.val();
            // console.log(files);

            this.setState({ files });
            this.setState({ loadingFiles: false });
          }
        });
    } catch (error) {
      this.setState({ readError: error.message, loadingFiles: false });
    }
  }

  validateEmail = (e) => {
    var email = e.target.value;

    // this.setState({...this.state.request_data, [e.data.target]: e.data.value})

    if (validator.isEmail(email)) {
      return this.setState({
        email: email,
        emailError: false,
        setEmailError: "Valid Email :)",
        emailChanged: true,
      });
    } else {
      return this.setState({
        emailError: true,
        setEmailError: "Please enter a valid email!",
        emailChanged: true,
      });
    }
  };

  onSubmit(e) {
    e.preventDefault();

    fetch("/api/upload/sendEmail", {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        blob: this.props.match.params.catId,
        client: this.state.files.client,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response;
        } else {
          console.log("Somthing went wrong!");
        }
      })
      .catch((err) => err);

    this.setState({ description: "Check your email for your results set!" });
  }

  render() {
    var formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
    return (
      <>
        <IndexNavbar />
        <FormHeader title="Your Footprint on Amazon" />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center mb-2">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    {this.state.files.status}{" "}
                    {this.state.files.status ===
                    "Infiltrating Amazon - it may take a few minutes, but sellers can't hide from us!" ? (
                      <div
                        className="spinner-grow text-center align-middle"
                        size="sm"
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </CardHeader>
                {this.state.loadingFiles ? (
                  <div className="text-center align-middle">
                    <div
                      className="spinner-border text-center align-middle"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <div>
                    <CardHeader className="bg-transparent">
                      <div className="text-center">
                        <i className="ni ni-collection" />
                        &nbsp;
                        {this.state.files.filename}
                      </div>
                    </CardHeader>
                    {!this.state.files.error_message ? (
                      <CardBody>
                        <div>
                          <i className="ni ni-books" />
                          &nbsp;{this.state.files.asin_count} ASINs uploaded
                          {this.state.files.asin_count <= 50 ? (
                            ""
                          ) : (
                            <small className="text-warning">
                              {" "}
                              [only the first 50 ASINs are included in the
                              summary]
                            </small>
                          )}
                        </div>
                        <div>
                          <i className="ni ni-bullet-list-67" />
                          &nbsp;{this.state.files.variations} potential product
                          variations found!
                        </div>
                        <div>
                          <i className="ni ni-shop" />
                          &nbsp;{this.state.files.listing_count} unique sellers
                          identified
                          {!(this.state.files.status === "Complete") &&
                          !(this.state.files.emailed === true)
                            ? " so far"
                            : "!"}
                        </div>
                        <div>
                          <i className="ni ni-tag" />
                          &nbsp;{this.state.files.offer_count} listings captured
                          {!(this.state.files.status === "Complete") &&
                          !(this.state.files.emailed === true)
                            ? " so far"
                            : "!"}
                        </div>
                        {this.state.files.product_value > 0 ? (
                          <div>
                            <i className="ni ni-box-2" />
                            &nbsp;
                            {this.state.files.inventory} units in inventory
                            reported
                            {!(this.state.files.status === "Complete") &&
                            !(this.state.files.emailed === true)
                              ? " so far"
                              : "!"}
                          </div>
                        ) : (
                          ""
                        )}
                      </CardBody>
                    ) : (
                      ""
                    )}

                    {this.state.files.error_message ? (
                      <CardBody>
                        <div>
                          <p className="text-danger">
                            <i className="ni ni-ambulance" />
                            &nbsp;{this.state.files.error_message}
                          </p>
                        </div>
                      </CardBody>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </Card>
            </Col>
          </Row>
          {this.state.files.product_value > 0 ? (
            <Row className="justify-content-center mb-2">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardBody>
                    <div>
                      <i className="ni ni-money-coins" />
                      &nbsp;
                      <b>
                        {formatter.format(
                          this.state.files.product_value.toFixed(0)
                        )}{" "}
                      </b>
                      of declared inventory value
                      {!(this.state.files.status === "Complete") &&
                      !(this.state.files.emailed === true)
                        ? " found so far"
                        : ""}
                      , which implies
                      <b>
                        {" "}
                        {formatter.format(
                          this.state.files.product_value.toFixed(0) * 13.61
                        )}{" "}
                      </b>{" "}
                      in annual sales!
                      <a class="footnote">
                        &sup1;
                        <span>
                          The calculation for annual sales is 13.61 multiplied
                          by declared inventory value (a.k.a. “on shelf”
                          inventory).
                          <br></br>
                          When the inventory level can not be determined, a
                          substitute value of the maximum amount the seller allows in a single order is used.
                        </span>
                      </a>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}

          {!(this.state.files.status === "Complete") ? (
            ""
          ) : (
            <Row className="justify-content-center mb-2">
              <Col lg="5" md="7">
                <Card className="bg-secondary border-0 mb-0">
                  <CardHeader className="bg-transparent">
                    <div className="text-center">
                      Enter your email address to receive a spreadsheet with
                      detailed results from infiltrating Amazon:
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form onSubmit={this.onSubmit}>
                      <FormGroup
                        className={classnames("mb-3", {
                          // focused: focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            className="form-control"
                            placeholder="Email"
                            name="email"
                            type="email"
                            onChange={(e) => this.validateEmail(e)}
                            value={this.state.email}
                          />
                          {/* <Input
                            placeholder="Email"
                            type="email"
                            //   onFocus={() => setfocusedEmail(true)}
                            //   onBlur={() => setfocusedEmail(true)}
                          /> */}
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        {this.state.emailError ? (
                          <p className="text-danger">
                            {this.state.setEmailError}
                          </p>
                        ) : (
                          ""
                        )}

                        {!this.state.emailError &&
                        this.state.emailChanged &&
                        !this.state.description ? (
                          <Button className="my-4" color="info" type="submit">
                            Get Results
                          </Button>
                        ) : (
                          ""
                        )}

                        <small className="text-success">
                          {this.state.description}
                        </small>
                      </div>
                    </Form>
                    <br></br>
                    <small>
                      Note: you must use an email address with the corporate
                      domain that matches the ASINs you uploaded.
                    </small>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  }
}
