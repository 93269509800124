import React, { Component } from "react";
import { auth } from "../../services/firebase";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  // FormGroup,
  Form,
  Input,
  // InputGroupAddon,
  // InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import FormHeader from "../Headers/FormHeader.js";
import IndexNavbar from "../Navbars/IndexNavbar.js";

export default class ListingExtraction extends Component {
  constructor(props) {
    super(props);
        this.state = {
          user: "",
        };
  }

  componentDidMount() {
        if (!auth().currentUser) {
          auth()
            .signInAnonymously()
            .then(() => {
              // Signed in..
              
              this.setState({ user: auth().currentUser.uid });
              
            });
        }
  }

  render() {

    var user = this.state.user;

    return (
      <>
        <IndexNavbar />
        <FormHeader
          title="Capture Your Brand's Footprint on Amazon"
          lead="How big is your Amazon seller problem?  Use this free tool to know how much of your product is being sold and how many 3rd party merchants are selling those products.   Simply upload a list of ASINs and you'll get your free summary!"
        />

        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                <CardHeader className="bg-transparent">
                  <div className="text-center">
                    <small>
                      Upload a CSV or Excel file to get your free summary. The
                      file must have an 'ASIN' column under the header “ASIN”.
                      <br></br>
                      <small>
                        Please note: this free tool will only analyze the first
                        50 ASINs on your list.
                      </small>
                    </small>
                  </div>
                </CardHeader>
                <CardBody className="px-lg-5 py-lg-5">
                  <Form
                    action="/api/upload/uploadFile"
                    method="post"
                    encType="multipart/form-data"
                  >
                    <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="client"
                        name="client"
                        placeholder="Name"
                        required
                      ></Input>
                    </InputGroup>
                    <br></br>
                    {/* <InputGroup className="input-group-merge input-group-alternative">
                      <Input
                        type="text"
                        id="email"
                        name="email"
                        placeholder={email}
                      ></Input>
                    </InputGroup>
                    <br></br> */}
                    {/* 
                    <small>
                      <label for="MarketplaceSelect">
                        Select Marketplace
                      </label>
                    </small> */}
                    <select
                      className="form-control"
                      id="marketplace"
                      name="marketplace"
                    >
                      <option value="Amazon US" defaultValue>
                        Amazon US
                      </option>
                      <option value="Amazon CAN">Amazon CAN</option>
                    </select>
                    <input type="hidden" id="id" name="id" value={user}></input>

                    <input
                      type="hidden"
                      id="email"
                      name="email"
                      value=""
                    ></input>

                    <input type="hidden" id="name" name="name" value=""></input>

                    <div className="text-center">
                      <Input
                        className="my-4"
                        type="file"
                        name="recfile"
                        placeholder="Select file"
                        accept=".csv,.xls,.xlsx"
                        required
                      />

                      <Button className="my-4" color="info" type="submit">
                        Upload
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
